// extracted by mini-css-extract-plugin
export var boxHolder = "styles-module--boxHolder--Bgac5";
export var breadcrumb = "styles-module--breadcrumb--9j79u";
export var containerHolder = "styles-module--containerHolder--N2AQI";
export var contentHolder = "styles-module--contentHolder--Ma6nK";
export var contentsHolder = "styles-module--contentsHolder--a0DaU";
export var header = "styles-module--header--iejXG";
export var holder = "styles-module--holder--4eYAW";
export var howHolder = "styles-module--howHolder--F0ufS";
export var image = "styles-module--image--AUZ0W";
export var kidsHolder = "styles-module--kidsHolder--APSBg";
export var listenCoughHolder = "styles-module--listenCoughHolder--JuQls";
export var numberHolder = "styles-module--numberHolder--97cVl";
export var rightCoughHolder = "styles-module--rightCoughHolder--ccfAl";
export var typesHolder = "styles-module--typesHolder--bjwuo";
export var whatHolder = "styles-module--whatHolder--GnQot";
export var whyHolder = "styles-module--whyHolder--rv0M3";