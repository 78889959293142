import * as React from "react";
import {Link, PageProps} from "gatsby";
import * as styles from "./styles.module.scss";
import {StaticImage} from "gatsby-plugin-image";
import classNames from "classnames";
import {Breadcrumb} from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import {ProductBar} from "../../../components/product-bar/product-bar";
import {Level} from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import {Helmet} from "react-helmet";
import blogImage from "../../../images/oksuruk_cover_2023.webp";
import {useEffect} from "react";
import {AnchorLink} from "gatsby-plugin-anchor-links";

const CoughPage: React.FC<PageProps> = ({location}: PageProps) => {
    useEffect(() => {
        if (location.hash) {
            scrollTo(location.hash);
        }
    }, []);
    return (
        <Layout
            stickyHeader={false}
            seoConfig={{
                title: "Öksürüğe Ne İyi Gelir? - Nasıl Geçer?",
                description:
                    "Öksürük nasıl geçer, öksürüğe ne iyi gelir? Öksürüğünüze nelerin iyi geleceğini öğrenmek için sayfamızı ziyaret edin!",
            }}
        >
            <Helmet>
                <script type="application/ld+json">
                    {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Öksürüğe Ne İyi Gelir? - Nasıl Geçer?, Nedenleri Nelerdir?","item": "https://www.bisolnatur.com.tr/oksuruk/oksuruge-ne-iyi-gelir-nasil-gecer"}
                ]
            }
          `}
                </script>
                <script type="application/ld+json">
                    {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Öksürüğe Ne İyi Gelir? - Nasıl Geçer?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2023-03-20",
              "dateModified": "2023-03-21"
            }
          `}
                </script>
                <script
                    type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Öksürük Nedir?","acceptedAnswer":{"@type":"Answer","text":"Günümüzde birçok kişi öksürük nedeniyle doktora başvurur. Çok faydalı ve doğal bir refleks olan öksürük, vücuda zararlı olan maddeleri üst ve alt solunum yollarıyla uzaklaştırma yöntemi olarak tanımlanır. İnsan vücudunda bulunan savunma mekanizmalarının en önemlisidir."}},{"@type":"Question","name":"Öksürük Tipleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"<ul><li>Akut Öksürük</li><li>Kronik öksürük </li><li>Kuru öksürük </li><li>Balgamlı öksürük</li></ul>"}},{"@type":"Question","name":"Öksürük Nedenleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Öksürük çeşitleri kendi aralarında çeşitlendiği için öksürük nedenlerini de öksürük tiplerine göre açıklamak gerekir. Öksürük tipleri arasında ilk sırayı süresine göre öksürük çeşitleri bulunur. Süresine göre öksürük tipleri de akut ve kronik olmak üzere iki çeşittir."}},{"@type":"Question","name":"Öksürüğe Ne İyi Gelir?","acceptedAnswer":{"@type":"Answer","text":"Öksürük ataklarınız yoğun geçiyorsa mutlaka bir doktora başvurmanız gerekir. Doktorunuz öksürük tipinize ve gün içindeki seyrine uygun olarak bir tedavi yöntemi belirler. Ancak öksürük ataklarınız için ilaçlardan farklı olarak evinizde de çeşitli tedavi yöntemleri uygulayabilirsiniz."}}]}`}</script>
            </Helmet>
            <Breadcrumb
                pathname={location.pathname}
                title="Öksürüğe Ne İyi Gelir? - Nasıl Geçer?"
                noBg={true}
                className={styles.breadcrumb}
            />
            <Container fullWidth>
                <div className={classNames(styles.header)}>
                    <StaticImage
                        className={styles.image}
                        src="../../../images/oksuruk_cover_2023.webp"
                        alt="Hapşuran çocuk görseli"
                        width={1680}
                        height={450}
                    />
                    <Container>
                        <Heading>Öksürüğe Ne İyi Gelir? - Nasıl Geçer?</Heading>
                        <ul>
                            <li>
                                <AnchorLink
                                    className="blue"
                                    to="#oksuruge-ne-iyi-gelir"
                                    title="Öksürüğe Ne İyi Gelir?"
                                >
                                    Öksürüğe Ne İyi Gelir?
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    className="blue"
                                    to="#akut-oksuruk-nasil-gecer"
                                    title="Akut Öksürük Nasıl Geçer?"
                                >
                                    Akut Öksürük Nasıl Geçer?
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    className="blue"
                                    to="#kronik-oksuruk-nasil-gecer"
                                    title="Kronik Öksürük Nasıl Geçer?"
                                >
                                    Kronik Öksürük Nasıl Geçer?
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    className="blue"
                                    to="#kuru-oksuruk-nasil-gecer"
                                    title="Kuru Öksürük Nasıl Geçer?"
                                >
                                    Kuru Öksürük Nasıl Geçer?
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    className="blue"
                                    to="#balgamli-oksuruk-nasil-gecer"
                                    title="Balgamlı Öksürük Nasıl Geçer?"
                                >
                                    Balgamlı Öksürük Nasıl Geçer?
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    className="blue"
                                    to="#hamilelikte-oksuruk-nasil-gecer"
                                    title="Hamilelikte Öksürük Nasıl Geçer?"
                                >
                                    Hamilelikte Öksürük Nasıl Geçer?
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    className="blue"
                                    to="#cocugunuzun-oksurugu-icin-ne-yapmalisiniz"
                                    title="Çocuğunuzun Öksürüğü İçin Ne Yapmalısınız?"
                                >
                                    Çocuğunuzun Öksürüğü İçin Ne Yapmalısınız?
                                </AnchorLink>
                            </li>
                        </ul>
                    </Container>
                </div>
            </Container>
            <Container>
                <div className={styles.holder}>
                    <p id="oksuruk-nedir">
                        Birçok hastalık kendisini öksürükle belli eder. Soğuk algınlığı ve grip gibi rahatsızlıkların dışında öksürük ciddi hastalıkların da habercisi olabilir. Her insan günde birkaç defa çeşitli nedenlerle öksürür. Bu öksürük
                        boğazınızı temizlemek için veya dışarıdan gelen toz vs gibi nedenlerle ortaya çıkabilir.
                        <br/><br/>
                        Sağlık profesyonelleri öksürüğün insan vücudunun solunum sisteminde yer alan en önemli savunma mekanizması olduğu görüşünde birleşirler. Bir refleks olarak gerçekleşen öksürük sayesinde akciğerleriniz her türlü etkiye karşı
                        korunur ve dışarıdan gelen istenmeyen maddelerin solunum yollarınıza girmesi önlenir. Bunun yanında öksürük solunum yolunda bulunan her türlü salgı maddesini dışarı atmayı ve boğazınızı balgamdan arındırmayı sağlar. Peki,
                        öksürük nedir, öksürüğe ne iyi gelir?
                    </p>
                    <section className={styles.whatHolder}>
                        <Level>
                            <Heading>Öksürük Nedir?</Heading>
                        </Level>
                        <p>
                            Günümüzde birçok kişi öksürük nedeniyle doktora başvurur. Çok faydalı ve doğal bir refleks olan öksürük, vücuda zararlı olan maddeleri üst ve alt solunum yollarıyla uzaklaştırma yöntemi olarak tanımlanır. İnsan vücudunda
                            bulunan savunma mekanizmalarının en önemlisidir.
                        </p>
                        <p>Sadece birkaç saniye bile sürse öksürük üç bölümden oluşur. Bunlar;</p>
                        <ul>
                            <li className="blue">Soluk alma</li>
                            <li className="blue">Kapatılan yutak kapağına doğru kuvvetli bir şekilde soluğu baskılama</li>
                            <li className="blue">Kanalın açılması nedeniyle şiddetli şekilde ciğerlerdeki havanın ağızdan geri verilmesi</li>
                        </ul>
                        <p>Bir refleks olmasına karşın öksürük hem istemli hem de istemsiz olarak gelişebilir. İstemli gelişen öksürük tipleri dışarıdan gelen yabancı maddelere bağlıyken istemsiz öksürük tipleri hastalık işaretçisidir. </p>
                    </section>
                    <section id="oksuruk-tipleri-nelerdir" className={styles.typesHolder}>
                        <div className={classNames(styles.listenCoughHolder)}>
                            <Level>
                                <Heading>Öksürük Tipleri Nelerdir? </Heading>
                            </Level>
                            <p>Pek çok kişi öksürüğün tipleri var mı diye sorabilir ancak öksürüğün de tipleri var. Öksürük tipleri;</p>
                            <p>1 . Süresine</p>
                            <p>2. Tipine göre olmak üzere iki ana grupta incelenir.</p>
                            <p>Her iki öksürük tipinin de kendi içinde çeşitleri vardır.</p>
                            <p className="mbt-20"><b>Süresine göre öksürük tipleri</b></p>
                            <ul>
                                <li className="blue">Akut Öksürük</li>
                                <li className="blue">Kronik öksürük</li>
                            </ul>
                            <p className="mbt-20"><b>Tipine göre öksürük tipleri ise</b></p>
                            <ul>
                                <li className="blue">Kuru öksürük</li>
                                <li className="blue">Balgamlı öksürük</li>
                            </ul>
                            <p>olmak üzere kendi içinde farklı tiplere ayrılırlar. Hekimler öksürüğü öksürük tipine göre tedavi ederler.</p>
                        </div>
                    </section>

                    <section id="oksuruk-nedenleri-nelerdir" className={styles.typesHolder}>
                        <div className={classNames(styles.listenCoughHolder)}>
                            <Level>
                                <Heading>Öksürük Nedenleri Nelerdir?</Heading>
                            </Level>
                            <p>Öksürük çeşitleri kendi aralarında çeşitlendiği için öksürük nedenlerini de öksürük tiplerine göre açıklamak gerekir.</p>
                            <p>Öksürük tipleri arasında ilk sırayı süresine göre öksürük çeşitleri bulunur. Süresine göre öksürük tipleri de akut ve kronik olmak üzere iki çeşittir.</p>
                            <p className="mbt-20"><b>Akut Öksürük Nedenleri:</b></p>
                            <p>Üç haftadan daha kısa süren öksürükler akut öksürük olarak tanımlanır. Akut öksürük aniden başlar ve iki ila üç hafta devam eder. Akut öksürük süresince gelişen öksürük atakları genellikle 2 hafta içerisinde
                                kendiliğinden geriler. Bu öksürük tipi nedene bağlı olarak tedavi edildiğinde kısa süre içinde iyileşir. Akut öksürük nedenleri şunlardır:</p>
                            <ul>
                                <li className="blue">Kirli hava,</li>
                                <li className="blue">Kimyasallar,</li>
                                <li className="blue">Toz ve polenler,</li>
                                <li className="blue">Evcil hayvan tüyü,</li>
                                <li className="blue">Nezle,</li>
                                <li className="blue">Pasif sigara içiciliği</li>
                                <li className="blue">Akciğerlere yabancı cisim kaçması</li>
                            </ul>
                            <p className="mbt-20"><b>Kronik Öksürük Nedenleri:</b></p>
                            <p>Süresine göre olan öksürük tipleri arasında yer alan kronik öksürük üç hafta ve daha uzun süren öksürüklere denir. Kronik öksürük nedenleri şunlardır:</p>
                            <ul>
                                <li className="blue">Mevsimsel alerjiler,</li>
                                <li className="blue">Hipertansiyon ilaçları,</li>
                                <li className="blue">Sigara kullanımı,</li>
                                <li className="blue">Tozlu ve dumanlı ortama maruz kalma</li>
                            </ul>
                            <p className="mbt-20"><b>Tipine Göre Öksürük Nedenleri Nelerdir?</b></p>
                            <p>Tipine göre öksürük çeşitleri kuru ve balgamlı öksürük olmak üzere iki çeşittir.</p>
                            <p className="mbt-20"><b>Kuru Öksürük Nedenleri:</b></p>
                            <p>Öksürük seyrinde balgamın veya mukusun olmadığı öksürük tipi kuru öksürük olarak tanımlanır. Öksürük anında balgam gelmemesi kuru öksürüğün temel ana belirtisidir. Boğazınızda gıcık hissine yol açabilir ve yaşam
                                kalitenizi ciddi ölçüde olumsuz etkiler. Kuru öksürüğün nedenleri ise</p>
                            <ul>
                                <li className="blue">Hava kirliliği,</li>
                                <li className="blue">Soğuk algınlığı,</li>
                                <li className="blue">Kalp yetmezliği,</li>
                                <li className="blue">Alerjiler,</li>
                            </ul>
                            <p className="mbt-20"><b>Balgamlı Öksürük Nedenleri:</b></p>
                            <p>Tipine göre öksürük çeşitleri arasında yer alan balgamlı öksürük de kişilerin yaşam kalitesini düşüren öksürük tipleri arasında yer alır. Yaş öksürük olarak da tanımlanan balgamlı öksürük, öksürük sırasında koyu
                                kıvamlı, sarımsı veya yeşil renkte balgamın gelmesi durumudur. Balgamlı öksürüğün nedenleri şunlardır:</p>
                            <ul>
                                <li className="blue">Kişinin soğuk havadan etkilenmesi,</li>
                                <li className="blue">Alerjiler,</li>
                                <li className="blue">Akciğerdeki hasar,</li>
                                <li className="blue">Soğuk algınlığı,</li>
                                <li className="blue">Sigara kullanımı,</li>
                                <li className="blue">Yabancı maddeler</li>
                            </ul>
                        </div>
                    </section>

                    <section id="oksuruge-ne-iyi-gelir" className={styles.typesHolder}>
                        <div className={classNames(styles.listenCoughHolder)}>
                            <Level>
                                <Heading>Öksürüğe Ne İyi Gelir?</Heading>
                            </Level>
                            <p>Öksürük ataklarınız yoğun geçiyorsa mutlaka bir doktora başvurmanız gerekir. Doktorunuz öksürük tipinize ve gün içindeki seyrine uygun olarak bir tedavi yöntemi belirler. Ancak öksürük ataklarınız için ilaçlardan farklı
                                olarak evinizde de çeşitli tedavi yöntemleri uygulayabilirsiniz. İşte öksürük tipleri için öksürüğün nasıl geçeceği ile ilgili detaylar.</p>
                        </div>
                    </section>

                    <section id="akut-oksuruk-nasil-gecer" className={styles.typesHolder}>
                        <div className={classNames(styles.listenCoughHolder)}>
                            <Level>
                                <Heading>Akut Öksürük Nasıl Geçer?</Heading>
                            </Level>
                            <p>Akut öksürük tipleri genellikle mikrokobik kaynaklı olarak ortaya çıkar. Öksürüğünüz üç haftayı geçtiyse mutlaka bir doktora başvurmanız gerekir. Akut öksürük genellikle soğuk algınlığı ile ortaya çıkar. Ateşin eşlik
                                ettiği akut öksürük tiplerinde tedavi yöntemi olarak genellikle antibiyotik tedavisi kullanılır. Akut öksürük tipleri doktor tarafından tedavi edilmelidir. Akut öksürüğünüz için doktorunuzun reçete ettiği ilaçların
                                yanı sıra bol miktarda ıhlamur çayı tüketebilirsiniz. Böylece, boğaz tahrişiniz hafifler.</p>
                        </div>
                    </section>

                    <section id="kronik-oksuruk-nasil-gecer" className={styles.typesHolder}>
                        <div className={classNames(styles.listenCoughHolder)}>
                            <Level>
                                <Heading>Kronik Öksürük Nasıl Geçer?</Heading>
                            </Level>
                            <p>Eğer, öksürüğünüz ile birlikte burun akıntısı, geniz akıntısı, hırıltı, nefes darlığı ve ağzınızda ekşimsi bir tat hissi yaşıyorsanız öksürüğünüz büyük ihtimalle kronik öksürük tipine girer. Öksürüğünüz üç haftadan
                                fazla sürdüyse, uykunuzu bölüyor ve yaşam kalitenizi etkiliyorsa mutlaka bir doktora görünmeniz gerekir. Sürekli devam eden kronik öksürük sizi yorar ve uyku bozukluğu, baş ağrısı, baş dönmesi, kusma ve aşırı terleme
                                gibi farklı sağlık sorunlarına da yol açabilir. Doktorunuz kronik öksürüğünüzü etkili bir şekilde tedavi etmek için öncelikle nedenini belirlemek ister. Sigara kullanıyorsanız sigarayı bırakmanızı ister. Yüksek
                                tansiyon için ilaç kullanıyorsanız yan etkisi öksürük olmayan farklı bir ilaç reçete edebilir. Öksürüğünüzde geniz akıntısı ve alerji etkiliyse mutlaka bunlara uygun ilaçlar verecektir.</p>
                            <p>Kronik öksürük şikâyetiniz için evde bol sıvı tüketmelisiniz. Öksürüğünüz kuru bir öksürükse doktorunuzun tavsiye edeceği bir pastil kullanabilirsiniz. Böylece, tahriş olan boğazınız hafifler. Sigara içiyorsanız veya
                                pasif içiciyseniz tütün dumanından kaçının. Öksürüğünüzü kesmek için günde bir çay kaşığı bal tüketebilirsiniz. Ancak 1 yaşının altındaki çocuğunuzun öksürüğü için bal önerilmez. Çünkü bal bebeklere zararlı bakteriler
                                içerebilir.</p>
                        </div>
                    </section>

                    <section id="kuru-oksuruk-nasil-gecer" className={styles.typesHolder}>
                        <div className={classNames(styles.listenCoughHolder)}>
                            <Level>
                                <Heading>Kuru Öksürük Nasıl Geçer?</Heading>
                            </Level>
                            <p>Her öksürük tipinde olduğu gibi kuru öksürüğünüz varsa ve geçmesi uzun sürdüyse mutlaka doktora müracaat etmeniz gerekir. Doktorunuz öksürüğün altında yatan nedene bağlı olarak tedavi için bir yol haritası çıkarır. Kuru
                                öksürüğün boğazınızı tahriş etmemesi için balgam söktürücü öksürük ilaçları, boğaz spreyi ve pastil çeşitleri reçete edebilir. Öksürüğe ateş de eşlik ediyorsa viral bir enfeksiyon olduğunu düşünerek ateş düşürücüleri
                                de reçeteye ekler. Kuru öksürüğün tedavisi için kuşburnu, papatya, adaçayı, yasemin ve ıhlamur çayları gibi farklı bitki çayları içebilirsiniz. Bunların yanında kuru öksürük için tavsiye edilen bitki kürlerini
                                deneyebilirsiniz.</p>
                        </div>
                    </section>

                    <section id="balgamli-oksuruk-nasil-gecer" className={styles.typesHolder}>
                        <div className={classNames(styles.listenCoughHolder)}>
                            <Level>
                                <Heading>Balgamlı Öksürük Nasıl Geçer?</Heading>
                            </Level>
                            <p>Balgamlı öksürük pek çok kişi tarafından yaş öksürük olarak da tanımlanır. Ayrıca, bu öksürük tipi balgamın akciğerden hava ile atılması olarak da isimlendirilir. Balgamlı öksürüğün nedenleri arasında genellikle grip ve
                                nezle yer alır. Balgamlı öksürüğünüze genel olarak vücut ağrısı veya göğüs ve sırt bölgesinde ağrılarla birlikte ateş, geniz akıntısı ve mide bulantısı da eşlik ediyorsa mutlaka bir doktora görünmeniz gerekir. Balgamlı
                                öksürüğünüz soğuk algınlığı nedeniyle ortaya çıktıysa bunun tedavisi diğer hastalıkların tedavisine göre değişiklik gösterir. Doktorunuz soğuk algınlığı ilaçları ile birlikte balgamlı öksürük için de çeşitli ilaçlar
                                reçete eder. Doktorunuzun vereceği ilaçların yanı sıra evde istirahat etmenizi de önerir.</p>
                            <p>Balgamlı öksürüğünüzün evde tedavisine destek olması için bitki çayları tüketebilir, sıcak duş alabilir, evinizin ısısını ve nemini ayarlayabilirsiniz. Balgamlı öksürüğün tedavisinde bol sıvı tüketmek önemlidir. Sıvı
                                tüketmek akıntının yol açtığı tahrişi en aza indirger. Bunun yanı sıra boğazdaki kuruluğu da önler. Balgamlı öksürüğünüzün tedavisi sona erene kadar parfüm, oda kokusu gibi güçlü kokulardan uzak durmanız tavsiye
                                edilir.</p>
                        </div>
                    </section>

                    <section id="hamilelikte-oksuruk-nasil-gecer" className={styles.typesHolder}>
                        <div className={classNames(styles.listenCoughHolder)}>
                            <Level>
                                <Heading>Hamilelikte Öksürük Nasıl Geçer?</Heading>
                            </Level>
                            <p>Anne adayları hamilelikleri süresince bulaşıcı hastalıklardan korunmaya çalışırlar. Bunun nedeni doktorların hamilelere vitamin dışında başka bir ilaç reçete etmemelerdir. Anne adayları öksürük sorununu genellikle üst
                                solunum yolları enfeksiyonu, soğuk algınlığı ve grip kaynaklı olarak yaşarlar.</p>
                            <p>Bebek bekleyen kadınların öksürük tedavisi genellikle öksürüğün tipi, şiddeti ve devam etme süresine göre yapılır. Birçok anne adayının öksürüğü kendiliğinden iyileşse de öksürüğünüz 1 haftadan uzun sürerse ve ateşiniz
                                yükselirse mutlaka doktorunuza gitmelisiniz. Öksürüğünüz soğuk algınlığıyla birlikte devam ediyorsa bolca sıvı tüketmeli ve istirahat etmeniz gerekir. Özellikle ılık su boğazınızı yumuşatmak için önerilenler arasında
                                ilk sırada gelir. Öksürüğünüz üst solunum yolu enfeksiyonu olarak başladıysa ve geniz akıntısıyla başınız dertteyse okyanus suyu içeren spreyler ve fizyolojik burun damlaları kullanabilirsiniz. İlerleyen dönemlerde
                                tekrar öksürük ve soğuk algınlığına yakalanmamak için C vitamini alabilirsiniz.</p>
                            <p>Ilık suya katacağınız bal ve limonlu suyu aç karnına içmek boğaz yollarınızın yumuşaması için yararlı olur. Öksürüğünüzün kısa sürede iyileşmesine yardımcı olması için bitki çayları tüketebilirsiniz. Kaynamış suyun
                                içine Ihlamur, zencefil ve kabuğu soyulmuş bir dilim elmayı koyup bir süre demledikten sonra taze bir şekilde içebilirsiniz. Bitki çayları boğazınızı yumuşatır ve rahatlatır. Bitki çayı gibi sıcak içeceklerin fazla
                                sıcak içilmesi boğaz için zararlıdır. Dolayısıyla içecekleri kaynar bir şekilde tüketmemeye dikkat etmelisiniz.</p>
                            <p>Birçok anne adayı öksürüğün bebeğine zarar vermesinden endişelenir. Oysa şiddetli öksürüğün bebeğinize bir zararı olmaz. İçi sıvı dolu amniyon kesesi bebeğinizi öksürük ve hapşırık gibi sarsıntılardan korur.</p>
                        </div>
                    </section>

                    <section id="cocugunuzun-oksurugu-icin-ne-yapmalisiniz" className={styles.typesHolder}>
                        <div className={classNames(styles.listenCoughHolder)}>
                            <Level>
                                <Heading>Çocuğunuzun Öksürüğü İçin Ne Yapmalısınız?</Heading>
                            </Level>
                            <p>Çocuk öksürmeye başladığında ebeveynler çok endişelenirler. Çünkü çocuklarda öksürüğe bir çok farklı çevresel ve biyolojik etken sebep olabilir. Çocuğunuz iki haftadan uzun süredir öksürüyorsa mutlaka doktoruna
                                başvurmalısınız.</p>
                            <p>Çocuğunuzun genel durumu iyiyse ve hırıltılı nefes alma ve ateş gibi sorunları yoksa kendiniz de tedavi edebilirsiniz. Çocuğunuzun boğazını rahatlatmak ve yumuşatmak için onun bol su ve sıvı almasını sağlamalısınız.
                                Çocuğunuz öksürüğün yanında burun tıkanıklığından şikâyet ediyorsa deniz suyu ya da fizyolojik serumlar imdadınıza yetişir. Çocuğunuzun öksürük nedenlerinden biri de hava kuruluğu olabileceğinden su buharından
                                yararlanabilirsiniz. Bunun yanı sıra çocuğunuzun öksürüğünü kontrol altına almak için evinizin nem ortamını da yüzde 50 düzeyinde tutmanız gerekir.</p>
                            <p>Çocuğunuzun öksürüğü için ıhlamur çayından da faydalanabilirsiniz. Çocuğunuz 6 aylıktan büyükse doktorun önerisiyle ılık ıhlamur çayı içerebilirsiniz. Günde bir veya iki defa içireceğiniz ılık ıhlamur çayı çocuğunuzun
                                boğazının rahatlamasını sağlar. Ciddi bir öksürük sorunu karşısında çocuğunuza gelişigüzel öksürük şurubu ve hatta antibiyotik vermeniz oldukça sakıncalıdır.</p>
                            <p>Bebeğiniz 6 aylıktan küçükse öksürük sorununa dikkat etmelisiniz. Öksürük sorunu basit bir soğuk algınlığı olabileceği gibi daha ciddi hastalıklar nedeniyle ortaya çıkabilir. Bebeğinizin öksürüğünü ihmal ettiğinizde
                                daha ciddi sorunla karşılaşabilirsiniz. Bebeğiniz üç aylıktan küçükse ve öksürüyorsa bebeğinizi vakit kaybetmeden doktora götürmelisiniz.</p>
                        </div>
                    </section>

                    <section className={styles.typesHolder}>
                        <div className={classNames(styles.listenCoughHolder)}>
                            <Level>
                                <Heading>Kuru Öksürüğe Doğal ve Etkili Öksürük Şurubu – Bisolnatur</Heading>
                            </Level>
                            <p>Bisolnatur, doğal içeriğiyle boğazda koruyucu bir tabaka oluşturarak, boğazın tahriş olmasını engeller ve kuru öksürüğü rahatlatır. Aynı zamanda, balgamlı öksürükte de yumuşatarak kolayca atılmasına yardımcı olur.</p>
                            <p className="mbt-20"><b>Bisolnatur’ün İçeriği</b></p>
                            <p>Doğal içerikli Bisolnatur, patentli Poliflav M.A. kompleksi ve bal içerir.</p>
                            <p className="mbt-20"><b>Poliflav M.A. kompleksi</b></p>
                            <p>Mızrak yapraklı sinirotundan elde edilen polisakkarit fraksiyonu ile kekikten elde edilen flavonoid fraksiyonundan oluşur.</p>
                            <p className="mbt-20"><b>Bal</b></p>
                            <p>Doğadaki en eski şifa kaynaklarından biri olan bal, solunum yollarında kayganlaştırıcı bir etkiye sahiptir. Öksürüğün sebep olduğu boğaz tahrişini rahatlatır.</p>
                            <p className="mbt-20"><b>Bisolnatur’ün Özellikleri</b></p>
                            <p>Doğal içeriğiyle öksürüğü rahatlatan Bisolnatur renklendirici, koruyucu, , paraben gibi hiçbir katkı maddesi içermez. Aynı zamanda, gluten de içermediği için çölyak hastaları tarafından da güvenle kullanılabilir.</p>
                            <p>Bisolnatur içerdiği doğal şeftali, limon ve portakal aromaları ile hoş bir tada sahiptir. T.C Sağlık Bakanlığı TİTCK’na* kayıtlı olan Bisolnatur, 1 yaşından büyük herkes tarafından kullanılabilir. Hamilelik ve emzirme
                                dönemlerinde ise doktora danışılarak kullanılabilir.</p>
                            <p className="mbt-20"><b>*Türkiye İlaç Tıbbi Cihaz Kurumu</b></p>
                            <p className="mbt-20"><b>Bisolnatur Nasıl Kullanılır?</b></p>
                            <p>Bisolnatur’u kullanmadan önce kullanım kılavuzu dikkatle okunmalıdır. Doğru şekilde kullanım için şişe açılmadan önce iyice çalkalanmalı ve kutu ile birlikte verilen kaşık kullanılmalıdır.</p>
                            <p className="mbt-20"><b>Hamile ve emzirenler doktora danışarak Bisolnatur’ü kullanabilir.</b></p>
                            <p>Tüketiciler için önerilen kullanım dozları şu şekildedir (bir doz=bir ölçü kaşığı):</p>
                            <ul>
                                <li className="blue">1-6 yaş arasındaki çocuklarda, 1 ölçü kaşığı (5 ml) olmak üzere günde iki doz</li>
                                <li className="blue">6 yaş ve üzerindeki çocuklarda 2 ölçü kaşığı (10 ml) olmak üzere günde iki doz</li>
                                <li className="blue">Yetişkinlerde ise 2 ölçü kaşığı (10 ml) olmak üzere günde iki veya üç doz (Aynı zamanda, yetişkinlerde, tüketicinin gerekli gördüğü durumlarda kısa aralıklarla günde 4 defaya kadar kullanılması
                                    mümkündür.)
                                </li>
                            </ul>
                            <p>Sağlık Bakanlığı’na kayıtlı olan Bisolnatur’ün 1 yaş üzeri çocuklar için kullanımı uygundur. Bisolnatur’ün kullanımında, öksürük belirtilerinin birkaç günlük tedaviden sonra belirtiler devam ettiği taktirde doktorunuza
                                danışınız.</p>
                            <p className="mbt-20"><b>Bisolnatur Saklama Koşulları</b></p>
                            <p>Bisolnatur’u oda sıcaklıgında, ısı ve ışıktan koruyarak saklayınız Çocukların ulaşamayacağı yerde saklayınız.. Şişesi açıldıktan sonra 3 ay içinde tüketilmelidir.</p>
                            <p className="mbt-20"><b>Kaynakça</b></p>
                            <ul>
                                <li>
                                    <p>
                                https://my.clevelandclinic.org/health/symptoms/17755-cough
                            </p>
                                </li>
                                <li>
                                    <p>
                                        https://www.healthline.com/health/types-of-coughs#paroxysmal-cough
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://www.healthline.com/health/cold-flu/how-to-get-rid-of-phlegm#humidity
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://americanpregnancy.org/healthy-pregnancy/pregnancy-complications/cough-cold-during-pregnancy/
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://www.nygh.on.ca/areas-care/maternal-newborn-and-paediatric-care/pregnancy-and-birth/guide-pregnancy-and-birth/during-pregnancy/coughs-and-colds-pregnancy
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://www.medicalnewstoday.com/articles/321134
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://www.tuasaude.com/en/how-to-get-rid-of-phlegm/
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://www.tuasaude.com/en/cough-during-pregnancy/
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://www.seattlechildrens.org/conditions/a-z/coughs-meds-or-home-remedies/
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://www.nhs.uk/conditions/baby/health/colds-coughs-and-ear-infections-in-children/
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://kidshealth.org/en/parents/childs-cough.html
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://www.medicalnewstoday.com/articles/324912
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://www.lung.org/lung-health-diseases/warning-signs-of-lung-disease/cough/learn-about-cough
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        https://pubmed.ncbi.nlm.nih.gov/12099769/
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </section>


                </div>
                <div className="date">
                    <p>Yayınlama Tarihi: 2023-03-20</p>
                    <p>Son güncellenme Tarihi: 2023-03-21</p>
                </div>
            </Container>
            <ProductBar/>
        </Layout>
    );
};
export default CoughPage;
